import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { Menu } from "../../pages/home/menu";
import logo from "../../images/afghanWitnessHeaderLogo.svg";
import style from "./header.module.css";

const redirectToC4adsSite = (e: any) => {
  window.open(
    "https://c4ads.org",
    '_blank'
  );
}

const redirectToAfghanWitnessSite = (e: any) => {
  window.open(
    "https://afghanwitness.org",
    '_blank'
  );
}

export const Header = () => {
  return (
    <div className={style.header}>
      <Link to="/" className={style.headerLink}>
        <img className={style.logo} src={logo} alt="C4ADS" useMap="#logomap" />
        <map name="logomap">
          <area shape="rect" coords="0,0,136,30" alt="Afghan Witness" href="https://afghanwitness.org" onClick={redirectToAfghanWitnessSite} />
          <area shape="rect" coords="136,0,226,30" alt="C4ADS" href="https://c4ads.org" onClick={redirectToC4adsSite} />
        </map>
      </Link>
      <Menu />
    </div>
  );
};
