import axios from "axios";
import { format } from "date-fns";
import { orderBy } from "lodash";
import { processGeojson } from "kepler.gl/processors";
import CacheManager from "./cacheManager";
import store from "../store/index";
import { attachAfghanBorderLine } from "../pages/keplergl/keplerMapUtils";
import { KmlParse } from "./kmlPraser";
// @ts-ignore
import AfghanBorderLine from "../data/afghanBorderLineUpdated.kml";

export interface EventDate {
  attrDateTime: string;
  textDateTime: string;
}
export interface LoadingCallbackArgument {
  type: "loading" | "error" | "success";
  message: string;
}
type RGBColorRange = [number, number, number];
type LoadingCallback = (message: LoadingCallbackArgument) => void;
type PercentageCallback = (percent: number) => void;
type LoadEventsDataInterface = (
  cacheManager: CacheManager,
  url: string,
  loadingCallback: LoadingCallback
) => Promise<any>;

export const CATEGORIES_COLOR_SETTING: Record<string, string> = {
  "damage/destruction": "#b76c8b",
  "explosion/shelling/bombing": "#b76c8b",
  "public punishment": "#b76c8b",
  "shootings/gunfire": "#b76c8b",
  "wounded/dead": "#b76c8b",

  displacements: "#bf8c19",
  violence: "#bf8c19",
  "capture/arrests": "#bf8c19",

  claim: "#a0b9d0",
  "movements/positions": "#a0b9d0",
  propaganda: "#a0b9d0",
  protests: "#a0b9d0",
  statement: "#a0b9d0",
  "weapons/ammo": "#a0b9d0",

  other: "#a5a5a5",
  services: "#a5a5a5",
};

export const DEFAULT_COLOR = "#bf8c19";

class ColorRangeManager {
  private currentColorsIndexesOrder: Record<string, number> = {};
  private currentColorRange: string[] = [];
  private categoriesInRightOrder: string[] = [];
  private categoryNotInListColorIndex: number = -1;
  private categoryNotInListColor: string = DEFAULT_COLOR;

  private getCurrentColorsIndexesOrder(): Record<string, number> {
    const dct: Record<string, number> = {};
    let counter: number = 0;

    for (let i = 0; i < this.categoriesInRightOrder.length; i++) {
      const item = this.categoriesInRightOrder[i];
      let color = CATEGORIES_COLOR_SETTING[item.toLowerCase()];

      if (!color) {
        color = this.categoryNotInListColor;
      }

      if (dct[color] !== undefined) {
        continue;
      }

      dct[color] = counter;
      counter += 1;
    }
    this.categoryNotInListColorIndex = dct[this.categoryNotInListColor];
    return dct;
  }

  public getCurrentColorRange() {
    let colorRange: string[] = new Array<string>(Object.keys(this.currentColorsIndexesOrder).length);
    for (let key in this.currentColorsIndexesOrder) {
      const index = this.currentColorsIndexesOrder[key];
      colorRange[index] = key;
    }
    return colorRange;
  }

  public addCategories() {
    let categories: string[] = [];

    return (categoryName?: string) => {
      if (!categoryName) {
        this.categoriesInRightOrder = categories;
        this.currentColorsIndexesOrder = this.getCurrentColorsIndexesOrder();
        this.currentColorRange = this.getCurrentColorRange();
        return;
      }
      if (!categories.includes(categoryName)) {
        categories.push(categoryName);
      }
    };
  }

  public getCategoryColor(categoryName: string) {
    if (!categoryName) {
      return this.categoryNotInListColor;
    }
    const color = CATEGORIES_COLOR_SETTING[categoryName.toLowerCase()];
    return !color ? this.categoryNotInListColor : color;
  }

  public getCategoryColorRangePos(categoryName: string): number {
    return this.currentColorsIndexesOrder[this.getCategoryColor(categoryName)] * (10 / this.currentColorRange.length);
  }
}

export const colorRangeManager = new ColorRangeManager();
export const HEAT_MAP_COLOR_RANGE: string[] = ["#5A1846", "#900C3F", "#C70039", "#E3611C", "#F1920E", "#FFC300"];

export const generateKey = (pre: string, date?: number): string => {
  return `${pre}_${date !== undefined ? date : new Date().getTime()}`;
};

export const blackOrWhite = (r: number, g: number, b: number): string => {
  const step = 382.5;
  const colorSum = r + g + b;

  if (colorSum >= step) {
    return "#000000";
  } else {
    return "#ffffff";
  }
};

export const hexToRGB = (hex: string): RGBColorRange => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};

export const disableObjectKeys = (dct: any, keys: any[]): any => {
  let newDct: any = {};
  for (let key in dct) {
    if (keys.includes(key)) {
      continue;
    }
    newDct[key] = dct[key];
  }
  return newDct;
};

export const getKeplerSelector = () => {
  const { keplerGl } = store.getState();
  return keplerGl;
};

export const itemInArray = (item: string, examples: string[]): boolean => {
  return examples.includes(item);
};

export const getDate = (dateData: string | number): EventDate => {
  const date = new Date(dateData);
  if (date.toString() === "Invalid Date") {
    return {
      attrDateTime: "",
      textDateTime: "",
    };
  }
  return {
    attrDateTime: format(date, "yyyy-MM-dd"),
    textDateTime: format(date, "dd/MM/yyyy"),
  };
};

export const compareArrays = <T>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      return false;
    }
  }
  return true;
};

export const blobReader = async (obj: File | Blob, percentageCallback: PercentageCallback): Promise<Uint8Array> => {
  const stream = obj.stream();
  // @ts-ignore
  const reader = stream.getReader();
  const buffer = new Uint8Array(obj.size);
  let offset = 0;

  const promise = new Promise<boolean>((resolve) => {
    reader.read().then(function readChunk({ done, value }: ReadableStreamReadResult<Uint8Array>): any {
      if (done) {
        resolve(true);
        return;
      }

      buffer.set(value, offset);
      offset += value.length;

      percentageCallback(Math.round((offset / obj.size) * 100));
      return reader.read().then(readChunk);
    });
  });

  await promise;
  return buffer;
};

export const addServiceInformation = (data: any): any => {
  const addCategory = colorRangeManager.addCategories();

  for (let i = 0; i < data.features.length; i++) {
    const [longitude, latitude] = data.features[i].geometry.coordinates;
    let time = new Date(data.features[i].properties.verifiedDate);
    data.features[i].properties.lat = latitude;
    data.features[i].properties.long = longitude;
    data.features[i].properties.uniqueIndex = data.features[i].properties.id;

    const category = data.features[i].properties.categories[0];
    if (category) {
      data.features[i].properties.category = category;
      addCategory(category);
    }

    data.features[i].properties["fillColor"] = hexToRGB(colorRangeManager.getCategoryColor(category));
    data.features[i].properties["Timeline of verified data"] = time.getTime();
    // This is necessary so that the kepler type detector does not recognize this number as a date, but recognizes it as a number.
    data.features[i].properties.timeNum = Number(String(time.getTime()) + ".01");
  }
  const features = orderBy(data.features, ["properties.timeNum"], ["desc"]);
  data.features = features.slice();
  addCategory();

  return data;
};

export const uint8ArrayToJsonObj = (buffer: Uint8Array): any => {
  const textDecoder = new TextDecoder();
  return JSON.parse(textDecoder.decode(buffer));
};

export const loadRemoteFile: LoadEventsDataInterface = async (
  cacheManager: CacheManager,
  url: string,
  loadingCallback: LoadingCallback // rewrite to {type: success or error, message: "Message text"}
) => {
  loadingCallback({ type: "loading", message: `Downloading started` });

  try {
    var response = await axios.get(url, {
      onDownloadProgress: (progressEvent) => {
        const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
        loadingCallback({ type: "loading", message: `${percent}% Loaded` });
      },
    });
  } catch (axiosError) {
    loadingCallback({
      type: "error",
      message: "Downloading failed. Please check your internet connection and reload the page.",
    });
    throw axiosError;
  }

  loadingCallback({ type: "loading", message: "Preparing data..." });
  const result = addServiceInformation(response.data);

  if (process.env.REACT_APP_CACHE_MODE === "true") {
    await cacheManager.put(url, new TextEncoder().encode(JSON.stringify(result)));
  }

  loadingCallback({ type: "success", message: "Finishing up..." });

  const borderData = await KmlParse(AfghanBorderLine);
  if (borderData) {
    attachAfghanBorderLine(processGeojson(borderData));
  }
  return result;
};

export const loadEventsData = async (
  url: string,
  cacheName: string,
  loadingCallback: LoadingCallback
): Promise<any> => {
  const cache = CacheManager.create(cacheName);

  let response = await cache.get(url);
  if (!response) {
    await cache.removeAll();
    return loadRemoteFile(cache, url, loadingCallback);
  }

  loadingCallback({ type: "loading", message: `Loading data...` });
  const blob = await response.blob();
  const buffer = await blobReader(blob, (percent: number) => {
    loadingCallback({ type: "loading", message: `${percent}% Loaded` });
  });

  loadingCallback({ type: "success", message: "Finishing up..." });

  const borderData = await KmlParse(AfghanBorderLine);
  if (borderData) {
    attachAfghanBorderLine(processGeojson(borderData));
  }

  return uint8ArrayToJsonObj(buffer);
};
