export const categoryList = [
  "Capture/Arrests",
  "Claim",
  "Damage/Destruction",
  "Displacements",
  "Explosion/shelling/bombing",
  "Movements/Positions",
  "Other",
  "Propaganda",
  "Protests",
  "Public Punishment",
  "Services",
  "Shootings/gunfire",
  "Statement",
  "Violence",
  "Weapons/Ammo",
  "Wounded/dead",
];

export const Actor = [
  "Civilian",
  "Taliban",
  "Designated Terrorist Organisation",
  "Other Armed Group",
  "Iran",
  "Pakistan",
  "Tajikistan",
  "Uzbekistan",
  "Military",
  "Other",
  "Unknown",
  "Not Applicable",
];

export const Victim = [
  "Civilian",
  "Taliban",
  "Designated Terrorist Organisation",
  "Other Armed Group",
  "Iran",
  "Pakistan",
  "Tajikistan",
  "Uzbekistan",
  "Other",
  "Government worker",
  "Unknown",
  "Not Applicable",
];