import React from "react";
import cn from "classnames";
import { Event as DataEvent } from "../../../interfaces/events";
import { blackOrWhite, getDate, hexToRGB } from "../../../utils/tools";
import eventStyles from "../../home/event/event.module.css";
import styles from "../keplergl.module.css";
import { violenceLevels } from "../../../constants/eventDataTypes";
import { ColorCategories } from "../../../components/colorCategories";

interface TooltipProps {
  object: DataEvent;
}

export const KeplerCustomTooltip = ({ object }: TooltipProps) => {
  const date = getDate(object.properties.verifiedDate);
  const violenceLevelObj = violenceLevels[object.properties.violenceLevel];

  return (
    <div className={cn(styles.keplerTooltipContainer, eventStyles.container)}>
      <div className={cn(styles.tooltipInfoContainer, eventStyles.infoContainer)}>
        <div className={eventStyles.criteriaContainer}>
          <div className={cn(eventStyles.criteriaInfo, styles.tooltipHeader)}>
            <time className={cn(eventStyles.time, styles.tooltipTime)} dateTime={date.attrDateTime}>
              {date.textDateTime}
            </time>
            <div
              style={{
                background: violenceLevelObj.color,
                color: blackOrWhite(...hexToRGB(violenceLevelObj.color)),
                padding: violenceLevelObj.padding,
              }}
              className={cn(eventStyles.violenceLevel, styles.tooltipGraphicContentLevel)}
            >
              Graphic Content Level: {violenceLevelObj.name}
            </div>
          </div>
          {object.properties.description && (
            <p className={eventStyles.text}>Description: {object.properties.description}</p>
          )}
          {object.properties.credit && <p className={eventStyles.text}>Actor details: {object.properties.credit}</p>}
          <ColorCategories categories={object.properties.categories} />
        </div>
      </div>
    </div>
  );
};
