import { Event } from "../../../interfaces/events";
import { FiltersData } from "../../../interfaces/filters";
import { ProxyArrayController, createProxyArray } from "../proxyCreator";
import { FoundPlace, GeneratorResult, PlaceLabel, SearchItem } from "./interfaces";
import { DEFAULT_MAP_STATE } from "../../../store";

export const DEFAULT_FILTERS_STATE = {
  categories: [],
  actor: [],
  victim: [],
  dateRange: null,
  onlyEventsMapFrame: false,
  chosenOption: "",
  query: "",
};

const stringItemToLowerCase = (item: string): string => {
  return item.toLowerCase();
};

type PlaceOption = string | null;
type PlaceParts = {
  country: PlaceOption;
  city: PlaceOption;
  province: PlaceOption;
};
interface Point {
  lat: number;
  long: number;
}
interface DataRect {
  min: Point;
  max: Point;
}

const FILTER_FIELDS = {
  COUNTRY: "country",
  CITY: "city",
  PROVINCE: "province",
  DISTRICT: "district",
  CATEGORIES: "categories",
  VICTIM: "victim",
  ACTOR: "actor",
};

export const rectIsValid = (rect: DataRect): boolean => {
  if (rect.min.long === Infinity || rect.min.lat === Infinity) {
    return false;
  }
  if (rect.max.long === -Infinity || rect.max.lat === -Infinity) {
    return false;
  }

  return true;
};

export const calculateCenter = () => {
  let initialRect = {
    min: { lat: Infinity, long: Infinity },
    max: { lat: -Infinity, long: -Infinity },
  };

  let counter = 0;
  const accumulator = {
    lat: 0,
    long: 0,
  };

  return {
    addPoint: (lat: number, long: number): DataRect => {
      if (lat > 90 || lat < -90 || long > 180 || long < -180) {
        return initialRect;
      }

      counter += 1;
      accumulator.lat = accumulator.lat + lat;
      accumulator.long = accumulator.long + long;

      if (lat < initialRect.min.lat) {
        initialRect.min.lat = lat;
      }

      if (long < initialRect.min.long) {
        initialRect.min.long = long;
      }

      if (lat > initialRect.max.lat) {
        initialRect.max.lat = lat;
      }

      if (long > initialRect.max.long) {
        initialRect.max.long = long;
      }

      return initialRect;
    },
    getCenter: (): Point => {
      if (counter > 0) {
        return {
          lat: accumulator.lat / counter,
          long: accumulator.long / counter,
        };
      } else {
        return {
          lat: DEFAULT_MAP_STATE.latitude,
          long: DEFAULT_MAP_STATE.longitude,
        };
      }
    },
  };
};

export class SearchEngine {
  private proxyArrayController: ProxyArrayController | null = null;
  public data: any[] = [];

  public saveCurrentZoomAndCoordinates: boolean = false;
  public ready: boolean = false;
  public inputFieldsFormat: any;
  public proxyData: any[] = [];
  public currentFields: Record<string, number> = {};
  public filteredIndexes: number[] = [];
  public currentCenter: Point = { lat: 0, long: 0 };
  public dataRect: DataRect = {
    min: { lat: Infinity, long: Infinity },
    max: { lat: -Infinity, long: -Infinity },
  };
  public filters: FiltersData = DEFAULT_FILTERS_STATE;
  public filtersInitialized: boolean = false;

  public getFilteredIndexes(): number[] {
    if (!this.proxyArrayController) {
      return [];
    }

    return this.proxyArrayController.getIndexes();
  }

  public setFilters(filters: FiltersData) {
    this.filtersInitialized = true;
    this.filters = filters;
  }

  private parseChosenOption(chosenOption: string): PlaceParts {
    const chosenOptionArray = chosenOption.split(",");

    const [country, province, city] = chosenOptionArray.map((item) => {
      if (item === "null" || item === "undefined") {
        return null;
      }
      return item;
    });

    return {
      country,
      city,
      province,
    };
  }

  private passPlaceChecks(chosenOption: string): boolean {
    return "null,null,null" === chosenOption || chosenOption === "";
  }

  private filterByChosenOption(placeParts: PlaceParts, row: any): number {
    let errorScore = 0;

    const { country, city, province } = placeParts;
    errorScore += country == row[this.currentFields[FILTER_FIELDS.COUNTRY]] ? 0 : 1;
    errorScore += province == row[this.currentFields[FILTER_FIELDS.PROVINCE]] ? 0 : 1;
    errorScore += city == row[this.currentFields[FILTER_FIELDS.CITY]] ? 0 : 1;

    return errorScore;
  }

  private indexOfFilter(query: string, row: any, fieldIds: string[]): number {
    if (query.length === 0) {
      return 0;
    }
    query = query.toLowerCase();

    let errorScore = 0;
    fieldIds.forEach((id) => {
      const value = row[this.currentFields[id]];
      if (!value) {
        errorScore += 1;
        return;
      }
      if (value.toLowerCase().indexOf(query) === -1) {
        errorScore += 1;
      }
    });

    return errorScore;
  }

  private filterByMultipleFields(filterOptions: string[], row: any, fieldIndex: number): number {
    if (fieldIndex === undefined || !row[fieldIndex]) {
      return 0;
    }
    const categories = this.elementsInArray(row[fieldIndex], filterOptions);
    return !categories ? 1 : 0;
  }

  private filterByField(filterOptions: string[], row: any, fieldIndex: number): number {
    const sectorAffected = this.elementsInArray(row[fieldIndex] ? [row[fieldIndex]] : [], filterOptions);
    return !sectorAffected ? 1 : 0;
  }

  public filter(): number[] {
    const placeParts = this.parseChosenOption(this.filters.chosenOption);
    const passPlaceChecks = this.passPlaceChecks(this.filters.chosenOption);

    const calc = calculateCenter();
    let filteredIndexes: number[] = [];
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      let errorScore = 0;

      if (!passPlaceChecks) {
        errorScore += this.filterByChosenOption(placeParts, row);
      }
      errorScore += this.indexOfFilter(this.filters.query, row, ["description"]);

      errorScore += this.filterByMultipleFields(
        this.filters.categories,
        row,
        this.currentFields[FILTER_FIELDS.CATEGORIES]
      );
      errorScore += this.filterByField(this.filters.victim, row, this.currentFields[FILTER_FIELDS.VICTIM]);
      errorScore += this.filterByField(this.filters.actor, row, this.currentFields[FILTER_FIELDS.ACTOR]);

      if (errorScore === 0) {
        this.dataRect = calc.addPoint(row[this.currentFields["lat"]], row[this.currentFields["long"]]);
        filteredIndexes.push(i);
      }
    }

    this.currentCenter = calc.getCenter();

    this.updateIndexes(filteredIndexes);
    return filteredIndexes;
  }

  private updateIndexes(indexes: number[]) {
    if (!this.proxyArrayController) {
      return;
    }
    this.proxyArrayController.updateIndexes(indexes);
  }

  private foundElementInArray(elem: string, arr: string[]): boolean {
    let found = false;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === elem) {
        found = true;
        break;
      }
    }
    return found;
  }

  private elementsInArray(elems: string[], arr: string[]): boolean {
    if (arr.length === 0) {
      return true;
    }

    elems = elems.map(stringItemToLowerCase);
    arr = arr.map(stringItemToLowerCase);

    for (let i = 0; i < elems.length; i++) {
      const element = this.foundElementInArray(elems[i], arr);
      if (element) {
        return true;
      }
    }

    return false;
  }

  getEvent(id: string) {
    const event = this.data.find((item) => {
      return item[this.currentFields["uniqueIndex"]] === id;
    });
    return !event ? null : event[0].properties;
  }

  getData() {
    return {
      fields: this.inputFieldsFormat,
      rows: this.proxyData,
    };
  }

  public initDataFields(data: any) {
    // data after geoJsonProcessor
    this.inputFieldsFormat = data.fields;
    for (let i = 0; i < data.fields.length; i++) {
      this.currentFields[data.fields[i].name] = i;
    }
  }

  public bindData(data: any) {
    this.initDataFields(data);
    const rows = data.rows;
    this.data = rows;

    const [proxyArray, proxyArrayController] = createProxyArray<any>(rows, this.filteredIndexes);
    this.proxyArrayController = proxyArrayController;
    this.proxyData = proxyArray;
    this.ready = true;
  }

  public searchPlaces(query: string, chunkSize: number) {
    const context = this;
    const initialStorage = this.data;
    query = query.toLowerCase();
    let alreadyFoundPlaces: FoundPlace[] = [];
    function* foundPlacesGenerator(index: number = 0): IterableIterator<FoundPlace[]> {
      const result = context.searchPlacesByName(query, index, chunkSize, alreadyFoundPlaces);
      alreadyFoundPlaces = alreadyFoundPlaces.concat(result.payload);
      const lastIndex = result.lastIndex;

      yield result.payload;

      if (lastIndex === initialStorage.length) {
        return;
      }
      // @ts-ignore
      return yield* foundPlacesGenerator(lastIndex);
    }
    return foundPlacesGenerator;
  }

  public searchEvents(place: FoundPlace | null, chunkSize: number, storage: any[]) {
    const context = this;

    function* foundEventsGenerator(index: number = 0): IterableIterator<Event[]> {
      let result: GeneratorResult<Event[]>;
      if (place) {
        result = context.searchEventsByPlaceName(place.name, index, chunkSize, storage);
      } else {
        result = context.getEventsChunk(index, chunkSize, storage);
      }
      const lastIndex = result.lastIndex;

      yield result.payload;

      if (lastIndex === storage.length) {
        return;
      }
      // @ts-ignore
      return yield* foundEventsGenerator(lastIndex);
    }
    return foundEventsGenerator;
  }

  private getEventsChunk(startIndex: number, chunkSize: number, storage: any[]): GeneratorResult<Event[]> {
    let events: Event[] = storage.slice(startIndex, startIndex + chunkSize);
    return {
      payload: events,
      lastIndex: startIndex + events.length,
    };
  }

  private searchEventsByPlaceName(
    query: string,
    startIndex: number,
    chunkSize: number,
    storage: any[]
  ): GeneratorResult<Event[]> {
    let foundEvents: Event[] = [];

    let i = startIndex;
    const ln = storage.length;
    query = query.toLowerCase();

    while (i < ln && foundEvents.length < chunkSize) {
      const foundName = this.compare(query, i, storage);
      i += 1;

      if (!foundName) {
        continue;
      }
      foundEvents.push(storage[i - 1]);
    }
    return { payload: foundEvents, lastIndex: i };
  }

  private searchPlacesByName(
    query: string,
    startIndex: number,
    chunkSize: number,
    initialFoundPlaces: FoundPlace[] = []
  ): GeneratorResult<FoundPlace[]> {
    let foundPlaces: FoundPlace[] = [];

    let i = startIndex;
    const ln = this.data.length;

    while (i < ln && foundPlaces.length < chunkSize) {
      const foundName = this.compare(query, i, this.data);
      i += 1;

      if (!foundName) {
        continue;
      }

      if (!this.placeIsAvailable(foundName.displayName, foundPlaces.concat(initialFoundPlaces))) {
        foundPlaces.push({
          name: foundName.displayName,
          filterName: foundName.filterName,
          description: foundName.description,
          index: foundName.index,
          firstFoundIndex: i - 1,
        });
      }
    }

    return {
      payload: foundPlaces,
      lastIndex: i,
    };
  }

  private placeIsAvailable(placeName: string, arr: SearchItem[]): boolean {
    const found = arr.find((item) => item.name === placeName);
    return !!found;
  }

  private placeFieldsToPlaceLabel(
    country: string,
    city: string,
    province: string
  ): {
    displayName: string;
    filterName: string;
  } {
    let arr = [city, province, country].filter((item) => !!item);
    const displayName = arr.join(", ");
    const filterName = `${country},${province},${city}`;

    return {
      filterName,
      displayName,
    };
  }

  private compareWithQuery(
    query: string,
    currentLabel: string
  ): {
    result: boolean;
    index: number;
  } {
    currentLabel = currentLabel.toLowerCase();
    const foundIndex = currentLabel.indexOf(query);
    return {
      result: foundIndex !== -1,
      index: foundIndex,
    };
  }

  private compare(query: string, index: number, storage: any[]): PlaceLabel | null {
    const row = storage[index];

    const country = row[this.currentFields["country"]];
    const province = row[this.currentFields["province"]];
    const city = row[this.currentFields["city"]];

    let description = row[this.currentFields["description"]];
    description = !description ? "" : description;

    let { displayName, filterName } = this.placeFieldsToPlaceLabel(country, city, province);

    // comparing must be only by displayName
    const placeResult = this.compareWithQuery(query, displayName);
    const descriptionResult = this.compareWithQuery(query, description);

    let displayDescription: boolean = descriptionResult.result;
    if (placeResult.result && descriptionResult.result) {
      displayDescription = false;
    }

    return !placeResult.result && !descriptionResult.result
      ? null
      : {
          displayName,
          filterName,
          index: displayDescription ? descriptionResult.index : placeResult.index,
          description: displayDescription ? description : undefined,
        };
  }
}
