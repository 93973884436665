import React from "react";
import { Collapse } from "antd";
import styles from "./about.module.css";

export const About = () => {
  return (
    <div className={styles.aboutWrapper}>
      <div className={styles.innerContainer}>
        <h1 className={styles.about}>About</h1>
        <div className={styles.aboutContainer}>
          <Collapse defaultActiveKey={["1", "2"]} bordered={false} expandIconPosition="end">
            <Collapse.Panel
              showArrow={true}
              className={styles.collapsePanel}
              header="About the Afghan Witness Project"
              key="1"
            >
              <div className={styles.collapseContent}>
                <p>
                  The Centre for Information Resilience (CIR) is an independent, non-profit social enterprise dedicated 
                  to countering disinformation, exposing human rights abuses, and combating online behaviour harmful to 
                  women and minorities.
                </p>
                <p>
                  In October 2021, CIR launched the Afghan Witness project to collect and verify videos, photos, satellite 
                  imagery or other media related to the situation in Afghanistan following the takeover by the de facto 
                  Taliban authorities. Our objective was to provide journalists, NGOs, policymakers and the public access 
                  to verified, trustworthy information.{" "}
                </p>
                <p>
                  Since then, the Afghan Witness project has used OSINT (open-source intelligence) techniques to record and 
                  verify events in Afghanistan. Verified information is stored in a database and represented on the Afghan 
                  Witness Map with one simple goal: to provide the world with timely and reliable information concerning 
                  ongoing events in Afghanistan.{" "}
                </p>
                <p>
                  CIR has collaborated with C4ADS to develop the Afghan Witness Map. C4ADS, a non-profit organization dedicated 
                  to defeating the illicit networks that threaten global peace and security, joined the Afghan Witness project 
                  with the aim of producing a map that would expand the ways in which researchers can interrogate data to advance 
                  their analysis.
                </p>
                <p>
                  The sections below explain how to use the map, describe the definitions used by the Afghan Witness project and the 
                  Afghan Witness Map, and list the issues researchers should acknowledge when interpreting data and handling graphic 
                  imagery.{" "}
                </p>
                <p>
                  For more information, visit the{" "}
                    <a target="_blank" href="https://www.afghanwitness.org/">
                      Afghan Witness project
                    </a>
                  .
                </p>
                <p>
                CIR also operates the Eyes on Russia project and Russia-Ukraine Monitor Map, which monitors the situation relating to 
                Russia's invasion of Ukraine. For more information, visit{" "}
                  <a target="_blank" href="https://eyesonrussia.org/">
                    Eyes on Russia
                  </a>
                  .
                </p>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header="How to Use the Map" key="2">
              <div className={styles.collapseContent}>
                <p>
                  The map draws on the database of videos, photos, satellite imagery or other media related to the 
                  situation in Afghanistan following the takeover by the de facto Taliban authorities that CIR's Afghan 
                  Witness project has been collecting and verifying since October 2021. CIR has verified the authenticity 
                  and location of all information contained in the database.
                </p>
                <p>You can select the data to be shown on the map in four ways:</p>
                <ul className={styles.list}>
                  <li>Select one or more Category of event</li>
                  <li>Select one or more Actor perpetrating the event</li>
                  <li>Select one or more Victim/Target affected by the event</li>
                  <li>Select start and end dates for the time period being researched</li>
                  <li>Search for keywords such as place names using the free text box</li>
                </ul>
                <p>
                  These search functions can be applied together or on their own. Descriptions of the 
                  Categories, Actor and Victim/Target are provided below.
                </p>
                <p>
                  The map will present data complying with your search parameters. Each pin on the map represents a
                  piece of verified data. You can continue your analysis of the data:
                </p>
                <ul className={styles.list}>
                  <li>Hover your cursor over a pin to display a brief description of the data's characteristics</li>
                  <li>
                    Click on a pin to display a preview box of the data and its characteristics including a link to the
                    original source of the data
                  </li>
                  <li>
                    Move the brackets along the timeline function at the bottom of the screen and press the “play”
                    button to view a time lapse of the emergence of the pins over the time period being researched
                  </li>
                  <li>
                    Use the 'Draw on map' tool to search for events that occurred in specific locations
                  </li>
                  <li>
                    Increase or decrease the magnification of the map and search for only events within the map frame
                  </li>
                </ul>
                <p>
                For an interactive tutorial on using the map, please refer to the video from our sister project{" "}
                  <a target="_blank" href="https://youtu.be/xaXz-EwUWOs">
                    Eyes on Russia &ndash; Mapping Russia’s War on Ukraine
                  </a>
                  .
                </p>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header="Contact" key="3">
              <div className={styles.collapseContent}>
                <div>
                  <p className={styles.underlinedText}>Get in Touch</p>
                  <p>For enquiries, citations, or feedback about the Afghan Witness Map, please contact us at info@afghanwitness.org</p>
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header="Definitions" key="4">
              <div className={styles.collapseContent}>
                <div>
                  <p className={styles.underlinedText}>Category</p>
                  <ul className={styles.list}>
                    <li>
                      Protests - Protest visible in footage
                    </li>
                    <li>
                      Wounded/Dead - Dead or injured people 
                    </li>
                    <li>
                      Capture/Arrests - One or more individuals are detained, arrested or captured
                    </li>
                    <li>
                      Violence - Beating, lashing or other sorts of violence seen in footage
                    </li>
                    <li>
                      Explosion/Shelling/Bombing - Bombing or explosion seen in footage
                    </li>
                    <li>
                      Statement - Statement by Taliban officials, reporters or eye witnesses
                    </li>
                    <li>
                      Weapons/Ammo - Visible weapons or munitions
                    </li>
                    <li>
                      Movements/Positions - Presence of Taliban, other armed groups or military vehicles, assets and/or troops
                    </li>
                    <li>
                      Damage/Destruction - Damage or destruction of buildings seen in footage
                    </li>
                    <li>
                      Shootings/Gunfire - Shootings or gunfire seen or audible in footage
                    </li>
                    <li>
                      Services - Aid, healthcare, or education
                    </li>
                    <li>
                      Displacements - Displacement of civilians seen in footage
                    </li>
                    <li>
                      Public punishment - Public punishment or displays seen in footage
                    </li>
                    <li>
                      Propaganda - Pro-Taliban celebrations, parades, etc
                    </li>
                    <li>
                      Claim - claim of something not seen in footage (e.g. claim of bombing but no damage seen or claim of torture but no physical evidence)
                    </li>
                    <li>
                      Other - Footage that does not fit in the above categories                      
                    </li>
                  </ul>
                </div>
                <div>
                  <p className={styles.underlinedText}>Actor</p>
                  <p>
                  Data is linked to the Actor listed below who have caused (visible or alleged) 
                  the incident or initiated the case on the map.{" "}
                  </p>
                  <ul className={styles.list}>
                    <li>
                      Civilian
                    </li>
                    <li>
                      Taliban
                    </li>
                    <li>
                      Designated Terrorist Organisation
                    </li>
                    <li>
                      Other Armed Group
                    </li>
                    <li>
                      Iran
                    </li>
                    <li>
                      Pakistan
                    </li>
                    <li>
                      Tajikistan
                    </li>
                    <li>
                      Uzbekistan
                    </li>
                    <li>
                      Unknown
                    </li>
                    <li>
                      Military (foreign)
                    </li>
                    <li>
                      Other
                    </li>
                    <li>
                      Not Applicable 
                    </li>
                  </ul>
                </div>
                <div>
                  <p className={styles.underlinedText}>Victim/Target</p>
                  <p>
                  Data is linked to the Victim or Target listed below who were 
                  affected (visible or alleged) by the incident on the map.{" "}
                  </p>
                  <ul className={styles.list}>
                    <li>
                      Civilian
                    </li>
                    <li>
                      Taliban
                    </li>
                    <li>
                      Designated Terrorist Organisation
                    </li>
                    <li>
                      Other Armed Group
                    </li>
                    <li>
                      Iran
                    </li>
                    <li>
                      Pakistan
                    </li>
                    <li>
                      Tajikistan
                    </li>
                    <li>
                      Uzbekistan
                    </li>
                    <li>
                      Other
                    </li>
                    <li>
                      Unknown
                    </li>
                    <li>
                      Government worker
                    </li>
                    <li>
                      Not applicable 
                    </li>
                  </ul>
                </div>
              </div>
            </Collapse.Panel>
            <Collapse.Panel className={styles.collapsePanel} header="Warnings and disclaimers" key="5">
              <div className={styles.collapseContent}>
                <ul className={styles.list}>
                  <li>
                    The map and underlying database only contains information that CIR's Afghan Witness project was 
                    able to collect and to verify. The database is not comprehensive of all events nor of all information 
                    about those events.
                  </li>
                  <li>
                    Each piece of data has been marked with a violence level to indicate the level of graphic content;
                    highly graphic content is not included in preview boxes.
                  </li>
                  <li>
                    CIR does not publish information that might put any individuals at risk. On occasion we may delay
                    publication or remove footage from the event pin on the map to ensure the safety of civilians in 
                    Afghanistan.
                  </li>
                  <li>
                    CIR only uses verified data when reporting on events; we are not responsible for claims about events
                    made on social media.
                  </li>
                  <li>
                    CIR's process for verifying data is rigorous and involves several stages of review. However, if you
                    believe any data to be inaccurate please email osint@info-res.org explaining why you think the data
                    is not correct and we will review the data.
                  </li>
                  <li>
                    The pins on this map link to verified videos, photos, or satellite imagery which have undergone verification 
                    to identify exactly where they were produced, and have been researched to verify date and time to the best 
                    of our ability.
                  </li>
                </ul>
              </div>
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
};
