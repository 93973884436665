import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import cn from "classnames";
import InfoIcon from "../../../images/info-icon.svg";
import MinimizeIcon from "../../../images/minimize-icon.svg";
import { ActiveListItem } from "../../../components/activeList";
import styles from "../keplergl.module.css";

export const InfoPopup: React.FC<ActiveListItem> = ({ onActive, onInActive, active }: ActiveListItem) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(active);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  useEffect(() => {
    setPopupOpen(active);
  }, [active]);

  const tooltipOpenChange = (open: boolean) => {
    if (popupOpen) {
      setTooltipOpen(false);
      return;
    }

    setTooltipOpen(open);
  };

  const openPopup = () => {
    onActive();
    setTooltipOpen(false);
    setPopupOpen(true);
  };

  const closePopup = () => {
    onInActive();
    setTooltipOpen(true);
    setPopupOpen(false);
  };

  return (
    <Tooltip placement="left" open={tooltipOpen} onOpenChange={tooltipOpenChange} title="Info">
      <div className={styles.expandContainer}>
        <img
          className={cn(styles.expandBtn, popupOpen && styles.hideExpandBtn)}
          onClick={openPopup}
          src={InfoIcon}
          alt="Info"
        />
        {popupOpen && (
          <div className={styles.expandPopupContent}>
            <button onClick={closePopup} className={styles.minimizeBtn}>
              <img src={MinimizeIcon} alt="Minimize info popup" />
            </button>
            <h1 className={styles.aboutPopupTitle}>The Afghan Witness Map</h1>
            <p className={styles.aboutPopupTitleSub}>From The Centre for Information Resilience</p>
            <br />
            <p>
              The Afghan Witness Map was created to facilitate the public's access to information and 
              understanding of the human rights situation in Afghanistan since 2021. Developed in 
              collaboration with C4ADS, the map draws on the database of videos, photos, satellite 
              imagery or other media that the project has collected and verified.
            </p>
            <p>
              More information about us and how to use the map is available
              <a href="/about" target="_blank"> at the About page.</a>
            </p>
          </div>
        )}
      </div>
    </Tooltip>
  );
};
