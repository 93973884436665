import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import { processGeojson } from "kepler.gl/processors";
import { Button } from "antd";
import cn from "classnames";
import styles from "./home.module.css";
import { DateRange, FiltersData } from "../../interfaces/filters";
import { Search } from "./search";
import { Kepler } from "../../pages/keplergl";
import SideBarArrow from "../../images/side-bar-arrow.svg";
import MapIcon from "../../images/map-icon.svg";
import CloseImage from "../../images/close-icon-black.svg";
import { createBindDataAction, setFilterActionCreator } from "./search/reducer";
import { FullScreenLoading } from "../../components/loading";
import { createOpenModalEventAction } from "./event/reducer";
import { EventModal } from "./event";
import { getKeplerSelector, loadEventsData, LoadingCallbackArgument } from "../../utils/tools";
import { SubmitEvent } from "./submitEvent";
import { SubmitFormFields } from "./submitEvent/submitEvent";
import { RootState } from "../../store";
import { AdaptiveModal } from "../../components/adaptiveModal";
import { Layout } from "../../components/layout";
import { About } from "../about";

interface DataLoadingState {
  loading: boolean;
  message: string;
}

const getResource = async (): Promise<string> => {
  const resourceUrl = process.env.REACT_APP_RESOURCE_URL;

  if (resourceUrl === undefined) {
    throw new Error("REACT_APP_RESOURCE_URL must be set");
  }

  return resourceUrl;
};

const labelColors: Record<string, string> = {
  Default: "#344054",
  Classic: "#344054",
  Earth: "#FFFFFF",
};

export const Home = () => {
  const [loading, setLoading] = useState<DataLoadingState>({
    loading: true,
    message: "",
  });
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [filters, setFilters] = useState<FiltersData>();
  const event = useSelector((state: RootState) => state.event);
  const { initialStateChanged } = useSelector((state: RootState) => state.searchEngine);
  const { currentMap } = useSelector((state: RootState) => state.keplerMapState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (event?.event != null) {
      navigate(`/event/${event.event.uniqueIndex}${window.location.search}`);
    }
  }, [event]);

  useEffect(() => {
    const loadFunction = (url: string) => {
      loadEventsData(url, "events_cache_name", (message: LoadingCallbackArgument) => {
        setLoading((prevDataLoading) => ({ ...prevDataLoading, message: message.message }));
      })
        .then((data: any) => {
          dispatch(createBindDataAction(processGeojson(data)));
          setLoading((prevDataLoading) => ({ ...prevDataLoading, loading: false }));
        })
        .catch((err) => {
          console.log("loadEventsData function error\n" + err);
        });
    };

    getResource().then((url) => {
      loadFunction(url);
    });
  }, [setLoading, dispatch]);

  useEffect(() => {
    if (!filters || loading.loading) {
      return;
    }

    const dateRange = filters.dateRange !== null ? filters.dateRange : getDefaultDateRange();

    dispatch(
      setFilterActionCreator({
        ...filters,
        dateRange: dateRange,
      })
    );
  }, [loading, filters, initialStateChanged]);

  const onSelectEvent = (eventObj: any) => {
    dispatch(
      createOpenModalEventAction({
        event: eventObj,
        ignoreZoom: true,
      })
    );
  };

  const getDefaultDateRange = (): DateRange => {
    const keplerSelector = getKeplerSelector();

    if (!keplerSelector?.map?.visState?.filters?.length || keplerSelector?.map?.visState?.filters?.length === 0) {
      return [new Date(), new Date()];
    }
    const defaultDateRange = keplerSelector.map.visState.filters[0].domain;
    if (defaultDateRange.length === 0) {
      return null;
    }

    const [startDate, endDate] = defaultDateRange;
    return [new Date(startDate), new Date(endDate)];
  };

  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const filtersOnChange = (newFilters: FiltersData) => {
    setFilters(newFilters);
  };

  return (
    <>
      {loading.loading ? (
        <FullScreenLoading>
          <span>{loading.message}</span>
        </FullScreenLoading>
      ) : (
        <AboutMapPopup />
      )}

      <div className={cn(styles.dashboard, sideBarOpen && styles.dashboardSideBarOpen)}>
        <div className={styles.content}>
          <div className={cn(styles.filtersContainer, !sideBarOpen && styles.filtersContainerClose)}>
            <Search onFilters={filtersOnChange} onSelectEvent={onSelectEvent} />
            <button
              onClick={toggleSideBar}
              className={cn(styles.toggleSideBarBtn, sideBarOpen && styles.toggleSideBarBtnOpen)}
            >
              <img src={SideBarArrow} className={cn(!sideBarOpen && styles.sideBarImageClose)} alt="Toggle side bar" />
            </button>
          </div>
          <p
            style={{ color: labelColors[currentMap.name] }}
            className={cn(styles.mapLabel, !sideBarOpen && styles.mapLabelFull)}
          >
            Afghan Witness Map
          </p>
          <div className={cn(styles.keplerMapContainer, sideBarOpen ? styles.keplerMin : styles.keplerFull)}>
            <Kepler />
          </div>
        </div>
      </div>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/about" element={<About />} />
        </Route>
        <Route
          path="event/:id"
          element={
            <div className={styles.eventModal}>
              <EventModal />
            </div>
          }
        />
      </Routes>
    </>
  );
};

const modalLocalStorageKey = "info-modal";

const AboutMapPopup = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const opened = localStorage.getItem(modalLocalStorageKey);
    if (!opened) {
      setOpen(true);
      localStorage.setItem(modalLocalStorageKey, "true");
    }
  }, []);

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <AdaptiveModal
        open={open}
        close={close}
        children={
          <div className={styles.aboutPopup}>
            <div className={styles.aboutPopupHeader}>
              <div className={styles.mapIconBlock}>
                <img src={MapIcon} alt="Map icon" className={styles.mapIcon} />
              </div>
              <div className={styles.aboutPopupHeaderTitle}>
                <h1 className={styles.aboutPopupTitle}>The Afghan Witness Map</h1>
                <p className={styles.aboutPopupTitleSub}>From The Centre for Information Resilience</p>
              </div>
            </div>
            <hr className={styles.divideLine} />
            <div className={styles.aboutPopupContent}>
              <p>
                The Afghan Witness Map has one simple goal: to provide the world with a visualisation of timely and
                 reliable information concerning ongoing human rights violations in Afghanistan.
              </p>
              <p>
                In October 2021, CIR launched the Afghan Witness project to collect and verify videos, photos, satellite
                 imagery or other media related to the situation in Afghanistan following the takeover by the Taliban.
                  Our objective was to provide journalists, NGOs, policymakers and the general public with access to
                   verified, trustworthy information.
              </p>
              <p>
                The project has produced a database of verified information covering events in Afghanistan. Developed
                 in collaboration with C4ADS, the Afghan Witness Map was created to facilitate the public's access and
                  understanding of this information. The Map draws on the database of videos, photos, satellite imagery
                   or other media that the project has collected and verified.
              </p>
              <p>
              More information about the project and guidance on how to use the map is available
                <a href="/about" target="_blank" onClick={close}>
                  {" "}
                  at the About page.
                </a>
              </p>
            </div>
            <div className={styles.aboutPopupFooter}>
              <span className={styles.footerInfoText}>
                <a className={styles.footerLink} onClick={close}>
                  Explore the Afghan Witness Map
                </a>
              </span>
            </div>
          </div>
        }
      />
      <button onClick={close} className={cn(styles.closeAboutPopup, !open && styles.closeAboutPopupHidden)}>
        <img src={CloseImage} alt="Close popup about" />
      </button>
      {/* Here I move this button outside the adaptive modal block, in order to avoid the unwanted animation caused by antd design */}
    </>
  );
};

// I will leave the "Submit Event" code until the final confirmation that this functionality is not needed.
const SubmitEventContainer = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = (fields: SubmitFormFields) => {
    setOpen(false);
    console.log(fields);
  };

  return (
    <>
      <Button className={styles.submitButton} type="primary" onClick={() => setOpen(true)}>
        Submit Event
      </Button>
      <SubmitEvent onSubmit={onSubmit} open={open} onClose={() => setOpen(false)} />
    </>
  );
};
